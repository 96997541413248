// utils/storeHours.ts

export function isStoreOpen(): boolean {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const totalMinutes = hours * 60 + minutes;

  const dayOfWeek = now.getDay();

  // Opening hours in minutes from midnight
  const morningStart = 11 * 60 + 30;
  const morningEnd = 14 * 60 + 30;
  const eveningStart = 17 * 60 + 30;
  const eveningEnd = 23 * 60;

  // Check if today is Tuesday
  if (dayOfWeek === 2) {
    return false; // The shop is always closed on Tuesdays
  }

  // Check if it's Saturady before 17:30 Uhr
  if (
    dayOfWeek === 6 &&
    (totalMinutes < eveningStart || totalMinutes >= eveningEnd)
  ) {
    return false; // The shop is always closed on Saturdays before 17:30
  }

  return (
    (totalMinutes >= morningStart && totalMinutes <= morningEnd) ||
    (totalMinutes >= eveningStart && totalMinutes <= eveningEnd)
  );
}
